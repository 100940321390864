import { $fetch, $post } from '@/services/axios';

export function fetch(params) {
  return $fetch(`customer/mails`, params);
}

export function history(params) {
  return $fetch(`customer/mails/history`, params);
}

export function retry(id) {
  return $post(`customer/mail/${id}/retry`);
}
