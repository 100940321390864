<template>
  <!-- prettier-ignore -->
  <index-content>
    <Tabs v-model="tab" name="customerMail" :animated="false">
      <template slot="extra">
        <div class="n-button-box">
          <n-button ghost custom-icon="fa fa-cog" text="template" @on-click="doTemplate" />
        </div>
      </template>
      <TabPane tab="customerMail" name="active" :label="this.$t('label.active')" class="pt-2">
        <customer-customer-email-active :condition="activeCondition" />
      </TabPane>
      <TabPane tab="customerMail" name="history" :label="this.$t('label.history')" class="pt-2">
        <customer-customer-email-history :condition="historyCondition" />
      </TabPane>
    </Tabs>
  </index-content>
</template>

<script>
import CustomerView from './view';
import CustomerCustomerEmailActive from './CustomerCustomerEmailActive';
import CustomerCustomerEmailHistory from './CustomerCustomerEmailHistory';

export default CustomerView.extend({
  name: 'CustomerCustomerEmail',
  components: { CustomerCustomerEmailActive, CustomerCustomerEmailHistory },
  data() {
    return {
      tab: 'active',
      activeCondition: {
        companyId: null,
        customerId: null,
        accountId: null,
        type: 0,
        status: 0,
        from: this.sod,
        to: this.eod,
        isHistory: false,
      },
      historyCondition: {
        companyId: null,
        accountId: null,
        from: this.halfYearAgo,
        to: this.eod,
        isHistory: true,
      },
    };
  },
  methods: {
    doTemplate() {
      let companyId = this.tab === 'active' ? this.activeCondition.companyId : this.historyCondition.companyId;
      this.pushMenu({ url: `/customer/customer/mail/${companyId}/template` });
    },
  },
});
</script>
