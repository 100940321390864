<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props" detail>
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2 v-for="(row, rIndex) in rows" :key="rIndex">
        <div v-for="(col, cIndex) in row" :key="cIndex">
          <n-field-id v-if="col.key === 'Order ID' || col.key === 'Execution ID'" :value="col.value" :custom-label="col.key" disabled />
          <n-field-text v-else :value="col.value" :custom-label="col.key" disabled />
        </div>
      </n-row-col2>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { isEmpty } from '@/helpers/utils';
import _ from 'lodash-es';

export default NModal.extend({
  name: 'ModalCustomerEmail',
  components: { NModal },
  computed: {
    rows() {
      if (isEmpty(this.model) || isEmpty(this.model.parameter)) return [];
      let p = JSON.parse(this.model.parameter);
      let arr = Object.keys(p).map(k => ({ key: this.format(k), value: p[k] }));
      let rows = [];
      let row = [];
      _.forEach(arr, (r, i) => {
        row.push(r);
        if (i % 2 === 1) {
          rows.push(row);
          row = [];
        } else if (i === arr.length - 1) {
          rows.push(row);
        }
      });
      return rows;
    },
  },
  methods: {
    format(k) {
      if (k === 'id') return 'ID';
      let key = '';
      if (k.indexOf('_') !== -1) k = this.toHump(k);
      for (let i = 0; i < k.length; i++) {
        let c = k.charAt(i);
        if (i === 0) key += c.toLocaleUpperCase();
        else if (/^[A-Z]+$/.test(c) || /^[0-9]+$/.test(c)) key += ` ${c}`;
        else key += c;
      }
      key = key.replace('Id', 'ID');
      return key;
    },
    toHump(k) {
      return k.replace(/_(\w)/g, function(all, letter) {
        return letter.toUpperCase();
      });
    },
  },
});
</script>
