<template>
  <!-- prettier-ignore -->
  <table class="table table-bordered table-hover table-striped text-center n-table">
    <thead>
    <tr>
      <th class="col-weight-1">{{ $t('label.customerId') }}</th>
      <th class="col-weight-1">{{ $t('label.accountId') }}</th>
      <th class="col-weight-1">{{ $t('label.type') }}</th>
      <th class="col-weight-1">{{ $t('label.issueStatus') }}</th>
      <th class="col-weight-2">{{ $t('label.email') }}</th>
      <th class="n-table-timestamp">{{ $t('label.issueTime') }}</th>
      <th class="n-table-timestamp">{{ $t('label.deliverTime') }}</th>
      <th v-if="isActive" v-table-action>{{ $t('label.action') }}</th>
      <th v-else class="action-detail">{{ $t('label.action') }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="row in records" :key="row.id">
      <td class="n-ellipsis">
        <n-modal-link v-if="isActive" name="customer.profile" :value="row.customerId" />
        <span v-else>{{ row.customerId }}</span>
      </td>
      <td class="n-ellipsis">
        <n-modal-link v-if="isActive" name="customer.account" :value="row.accountId" />
        <span v-else>{{ row.accountId }}</span>
      </td>
      <td v-enums:CustomerMailType="row.type"></td>
      <td v-enums:IssueStatus="row.issueStatus"></td>
      <td class="n-ellipsis" :title="row.email">{{ row.email }}</td>
      <td>{{ row.issueTime | datetime }}</td>
      <td>{{ row.deliverTime | datetime }}</td>
      <td class="n-button-box">
        <nb-custom type="info" text="detail" @on-click="openModal(row)" />
        <nb-confirm v-if="isActive" custom-icon="fas fa-sync" text="retry" :disabled="row.issueStatus !== $enums.IssueStatus.FAILURE" @on-ok="doRetry(row.id)" />
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import ModalCustomerEmail from './ModalCustomerEmail';
import { retry } from '@/api/customer/customer-customer-email';
import { createModal } from '@/helpers/modal';

export default {
  name: 'CustomerEmailTable',
  props: {
    isActive: Boolean,
    records: Array,
  },
  methods: {
    openModal(model = {}) {
      createModal(ModalCustomerEmail, { model, on: this });
    },
    doRetry(id) {
      return retry(id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/style/table';

.n-table {
  min-width: 1200px;

  th.rw-action {
    width: 9rem;
  }

  th.ro-action {
    width: $action-detail;
  }
}
</style>
